import React from 'react';
import './Form.css';
import Signature from '../Signature/Signature';
import InputField from '../InputField/InputField';
import Checkbox from '../Checkbox/Checkbox';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      description: '',
      slogan: '',
      email: '',
      phone: '',
      addHiring: false,
      addFreelancerMonster: false,
      useMySugrLogo: false,
      addRoche: false,
      checked: false,
      inputNotEmpty: false,
    };
  }

  handleChange = field => event => {
    this.setState(
      {
        [field]: event.target.value,
      },
      () => {},
    );
  };

  handleCheck = option => event => {
    this.setState(
      {
        [option]: event.target.checked,
      },
      () => {},
    );
  };

  render() {
    return (
      <div>
        <header className="page-header">
          <img
            src="https://www.mysugr.com/static/assets/mysugr-logo.svg"
            className="logo"
            alt="logo of app"
          ></img>
          <h1 className="title">Email signature generator</h1>
        </header>
        <div className="container" data-test="form-component">
          <div className="divForm" data-test="form">
            <div className="alert" role="alert">
              If something goes wrong, please contact <b>Jamie Munford</b>.
              <p>
                If you encounter any issues when uploading the signature into
                the mail signature settings, please retry generating a new one
                using Google Chrome (recommended) or Firefox.
              </p>
            </div>
            <form>
              <InputField
                className="form-control input-first"
                title="First name"
                maxLength="32"
                value={this.setState.firstName}
                onChange={this.handleChange('firstName')}
              />
              <InputField
                title="Middle name (Optional)"
                maxLength="32"
                className="form-control input-middle"
                value={this.state.middleName}
                onChange={this.handleChange('middleName')}
              />
              <InputField
                title="Last name"
                maxLength="32"
                className="form-control input-last"
                value={this.state.lastName}
                onChange={this.handleChange('lastName')}
              />
              <InputField
                title="E-mail"
                maxLength="75"
                className="form-control input-email"
                value={this.state.email}
                onChange={this.handleChange('email')}
              />
              <div className="form-element">
                <label className="labelInput">Extras</label>
                <Checkbox
                  className="checkbox-use-mysugr-logo"
                  checked={this.state.useMySugrLogo}
                  onChange={this.handleCheck('useMySugrLogo')}
                  name="signature-use-mysugr-logo"
                  value="use-mysugr-logo"
                  title="Don't use my monster avatar (If you have one)"
                />
                <Checkbox
                  className="checkbox-roche"
                  checked={this.state.addMySugrRocheAvatar}
                  onChange={this.handleCheck('addRoche')}
                  name="signature-roche"
                  value="add-roche"
                  title="Include the Roche logo"
                />
                <Checkbox
                  className="checkbox-hiring"
                  checked={this.state.addHiring}
                  onChange={this.handleCheck('addHiring')}
                  name="signature-hiring"
                  value="add-hiring"
                  title='Add "We are hiring!"'
                />
                <Checkbox
                  className="checkbox-freelance"
                  checked={this.state.addFreelancerMonster}
                  onChange={this.handleCheck('addFreelancerMonster')}
                  name="signature-freelance"
                  value="add-freelance-monster"
                  title="I work as a contractor for mySugr"
                />
              </div>
              <InputField
                title="Description (Optional)"
                maxLength="75"
                className="form-control input-description"
                value={this.state.description}
                onChange={this.handleChange('description')}
                smallDescription="Your field of work, your position"
              />
              <InputField
                title="Slogan (Optional)"
                maxLength="75"
                className="form-control input-slogan"
                value={this.state.slogan}
                onChange={this.handleChange('slogan')}
                smallDescription='Something like "T1 diabetic since 90"'
              />
              <InputField
                title="Phone number (Optional)"
                maxLength="16"
                className="form-control input-phone"
                value={this.state.phone}
                onChange={this.handleChange('phone')}
              />
            </form>
          </div>
          <Signature passState={this.state}></Signature>
        </div>
      </div>
    );
  }
}

export default Form;
