import React from 'react';
import MonsterImage from '../MonsterImage/MonsterImage';

class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonWasClicked: false,
    };
  }

  handleButtonClick = event => {
    this.setState({ buttonWasClicked: true });
    setTimeout(() => {
      this.setState({ buttonWasClicked: false });
    }, 3000);

    const copyBoxElement = this.copyBoxRef.current;
    copyBoxElement.contentEditable = true;
    copyBoxElement.focus();
    document.execCommand('selectAll');
    document.execCommand('copy');
    copyBoxElement.contentEditable = false;
    document.getSelection().empty();
  };

  copyBoxRef = React.createRef();

  render() {
    return (
      <div className="mailSignature" data-test="signature-component">
        <div className="copyBox" ref={this.copyBoxRef}>
          {!(
            this.props.passState.firstName && this.props.passState.lastName
          ) && (
            <h2
              className="beforeSignature"
              style={{
                textAlign: 'center',
                color: '#343434',
                padding: "2em"
              }}
            >
              Your signature will be generated after you enter your first and
              last name ✍️
            </h2>
          )}
          {this.props.passState.firstName && this.props.passState.lastName && (
            <table
              className="signatureTable"
              cellSpacing="0"
              cellPadding="0"
              border="0"
              width="300px"
              style={{
                lineHeight: '14px',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                border: 'none',
                fontFamily: 'Lato, "Helvetica Neue", Helvetica, Arial, sans-serif',
              }}
            >
              <tbody>
                <tr id="signature">
                  <td style={{ maxWidth: 108 }}>
                    <MonsterImage passState={this.props.passState} />
                  </td>
                  <td
                    style={{
                      width: 380,
                      fontSize: 12,
                      paddingLeft: 16,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div
                      style={{
                        maxWidth: '139px',
                        borderBottom: '2px solid #72941F',
                        marginBottom: '8px',
                        paddingBottom: '8px',
                      }}
                    >
                      <span style={{ color: '#8EB927', fontSize: '14px' }}>
                        <strong>
                          {this.props.passState.firstName}{' '}
                          {this.props.passState.middleName}{' '}
                          {this.props.passState.lastName}
                        </strong>
                      </span>
                      <br></br>
                      {this.props.passState.description && (
                        <div style={{ marginTop: '2px' }}>
                          {' '}
                          {this.props.passState.description}
                        </div>
                      )}
                    </div>
                    <span
                      style={{
                        fontSize: 12,
                      }}
                    >
                      mySugr
                    </span>
                    <span
                      style={{
                        fontSize: 8,
                        position: 'relative',
                        display: 'inline',
                        verticalAlign: 'text-bottom',
                      }}
                    >
                      ®
                    </span>{' '}
                    <br></br>
                    {this.props.passState.email && (
                      <div style={{ marginTop: '4px' }}>
                        <a
                          style={{
                            color: '#8F8F8F',
                            textDecoration: 'none',
                          }}
                          href={'mailto:' + this.props.passState.email}
                        >
                          {this.props.passState.email}
                        </a>
                      </div>
                    )}
                    {this.props.passState.phone && (
                      <div style={{ marginTop: '4px' }}>
                        <span
                          style={{
                            color: '#8F8F8F',
                          }}
                        >
                          Phone: {this.props.passState.phone}
                          <br></br>
                        </span>
                      </div>
                    )}
                    {this.props.passState.slogan && (
                      <div style={{ marginTop: '4px' }}>
                        <span
                          style={{
                            color: '#8F8F8F',
                          }}
                        >
                          {this.props.passState.slogan}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr id="hiring">
                  {this.props.passState.addHiring && (
                    <td
                      colSpan="2"
                      style={{
                        color: '#999999',
                        fontSize: 12,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <div
                        style={{
                          border: 'solid 1.5px #E86A0F',
                          borderRadius: '16px',
                          width: '244px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          display: 'table',
                          color: '#E86A0F',
                          marginTop: '20px',
                          padding: '4px 10px 2px',
                          height: '27px;',
                          borderCollapse: 'separate',
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'left',
                            display: 'table-cell',
                          }}
                        >
                          WE ARE HIRING!{' '}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          <a
                            href="https://mysugr.com/jobs"
                            style={{
                              fontSize: '8px',
                              color: '#E86A0F',
                              textDecoration: 'none',
                              position: 'relative',
                              bottom: '2px',
                            }}
                          >
                            {' '}
                            See open positions
                          </a>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
                <tr id="slogan">
                  <td colSpan="2">
                    <h1
                      style={{
                        color: 'black',
                        whiteSpace: 'nowrap',
                        marginBottom: '20px',
                        marginTop: '20px',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        lineHeight: '26px',
                      }}
                    >
                      Make diabetes suck less
                      <span
                        style={{
                          fontSize: 8,
                          position: 'relative',
                          verticalAlign: 'baseline',
                          marginLeft: 2,
                        }}
                      >
                        ®
                      </span>{' '}
                    </h1>
                  </td>
                </tr>
                <tr id="social-media">
                  <td
                    colSpan="2"
                    style={{
                      fontSize: 12,
                      paddingBottom: 20,
                      paddingRight: '20px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <table
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ paddingRight: '20px'}}
                      border="0"
                      width="210"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="https://mysugr.com/blog"
                              style={{ display: 'inline-block' }}
                            >
                              <img
                                src="https://assets.mysugr.com/email/img/signature-icons/mysugr_icon.png"
                                alt="mysugr-icon"
                                style={{
                                  width: 25,
                                  height: 20,
                                  border: 'none',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              ></img>
                            </a>
                          </td>
                          <td
                            style={{
                              paddingLeft: 20,
                              paddingBottom: 0,
                            }}
                          >
                            <a
                              href="https://linkedin.com/company/mysugr"
                              style={{ display: 'inline-block' }}
                            >
                              <img
                                src="https://assets.mysugr.com/email/img/signature-icons/linkedln_icon.png"
                                alt="linkedin-icon"
                                className="linkedinImg"
                                style={{
                                  width: 20,
                                  height: 20,
                                  border: 'none',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              ></img>
                            </a>
                          </td>
                          <td
                            style={{
                              paddingLeft: 20,
                            }}
                          >
                            <a
                              href="https://facebook.com/mysugr"
                              style={{ display: 'inline-block' }}
                            >
                              <img
                                src="https://assets.mysugr.com/email/img/signature-icons/facebook_icon.png"
                                alt="facebook-icon"
                                className="facebookImg"
                                style={{
                                  width: 10,
                                  height: 21,
                                  border: 'none',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              ></img>
                            </a>
                          </td>

                          <td
                            style={{
                              paddingLeft: 20,
                              paddingBottom: 0,
                            }}
                          >
                            <a
                              href="https://twitter.com/mysugr"
                              style={{ display: 'inline-block' }}
                            >
                              <img
                                src="https://assets.mysugr.com/email/img/signature-icons/twitter_icon.png"
                                alt="twitter-icon"
                                className="twitterImg"
                                style={{
                                  width: 22,
                                  height: 17,
                                  border: 'none',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              ></img>
                            </a>
                          </td>
                          <td
                            style={{
                              paddingLeft: 20,
                              paddingBottom: 0,
                            }}
                          >
                            <a
                              href="https://instagram.com/mysugr"
                              style={{ display: 'inline-block' }}
                            >
                              <img
                                src="https://assets.mysugr.com/email/img/signature-icons/instagram_icon.png"
                                alt="instagram-icon"
                                className="instagramImg"
                                style={{
                                  width: 20,
                                  height: 20,
                                  border: 'none',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              ></img>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr
                  id="footer"
                  style={{
                    color: '#8F8F8F',
                    lineHeight: '14px',
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                  }}
                >
                  <td colSpan="2" style={{ fontSize: '12px' }}>
                    <div>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        mySugr GmbH |
                        <span> Trattnerhof 1/5 OG, A-1010 Vienna</span>
                        <br></br>
                      </span>
                    </div>
                    Managing Director | Elisabeth Kölbel
                    <br></br>
                    Company Register | FN 376086 v<br></br>
                    Place of Jurisdiction | Commercial Court Vienna
                    <div style={{ marginTop: '20px' }}>
                      <span>Confidentiality Note </span>
                      <div
                        style={{
                          textAlign: 'left',
                          maxWidth: '279px',
                          lineHeight: '12px',
                          fontSize: '10px',
                        }}
                        className="confidentialityText"
                      >
                        This message is intended only for the use of the named
                        recipient(s) and may contain confidential and/or
                        privileged information. If you are not the intended
                        recipient, please contact the sender and delete the
                        message. Any unauthorized use of the information
                        contained in this message is prohibited.
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="butonContainer">
          {this.props.passState.firstName && this.props.passState.lastName && (
            <button className="copy" onClick={this.handleButtonClick}>
              Copy signature
            </button>
          )}

          <br></br>
        </div>
        {this.state.buttonWasClicked && (
          <h4 className="signature-copied">
            The signature was copied to your clipboard
          </h4>
        )}
      </div>
    );
  }
}

export default Signature;
