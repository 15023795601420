import React from 'react';

const InputField = props => {
  return (
    <div className="form-element">
      <label className="labelInput">{props.title}</label>
      <input
        type="text"
        className="form-control"
        value={props.value}
        onChange={props.handleChange}
        {...props}
      ></input>
      <small>{props.smallDescription}</small>
      <br></br>
    </div>
  );
};

export default InputField;
